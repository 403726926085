@import '~app/variables';

.dot {
  $size: 15px;

  display: inline-block;
  vertical-align: top;
  height: $size;
  width: $size;
  line-height: $size;
  border-radius: 50%;
  font-size: 10px;
  letter-spacing: -1px;
  text-align: center;
  color: $color-white;
}

.small {
  $size: 13px;

  height: $size;
  width: $size;
  min-width: $size;
  line-height: $size;
  margin-right: 4px;
  margin-left: 4px;
}

.dot:not(:empty) {
  border-radius: 5px;
  width: auto;
  padding-left: 3px;
  padding-right: 4px;
}
