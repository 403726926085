@import '~app/mixins';

.buttons {
  display: flex;
  justify-content: center;

  button {
    min-width: 100px;
  }

  button + button {
    margin-left: 15px;
  }
}

.picker {
  margin: 10px 10px 15px;
  text-align: center;
}

.transparent {
  margin-bottom: 15px;
  text-align: center;

  button {
    width: 213px;
  }
}
